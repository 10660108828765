<div id="header" class="main-bg p-2 sticky">
    <div class="align-items-center d-flex justify-content-between">
        <div class="slide-bar">
            <app-slide-bar [items]="items" [techs]="techs"></app-slide-bar>
        </div>
        <div>
            <img height="50px" src="assets/img/logo.PNG" routerLink="" style="cursor: pointer;">
        </div>
        <div class="dropdown mobile">
            <div class="item dropdown-toggle" data-toggle="dropdown"><span>CÔNG NGHỆ</span></div>
            <ul class="dropdown-menu main-bg">
                <div class="dropright p-2" *ngFor="let t of techs">
                    <li class="drop-item dropdown-toggle" data-toggle="dropdown"><span class="text-under">{{t.title}}</span></li>
                    <ul class="dropdown-menu main-bg">
                        <li class="dropdown-toggle drop-item p-2" *ngFor="let m of t.main" data-toggle="dropdown"><span class="inline-drop" routerLink="/item/{{m.slug}}">{{m.name}}</span></li>
                    </ul>
                </div>
            </ul>
        </div>
        <div class="item mobile" *ngFor="let i of items">
            {{i}}
        </div>
        <div class="mobile"></div>
        <div class="mobile"></div>
        <div class="item mobile">BẢNG ĐIỀU KHIỂN</div>
        <button type="button" class="btn btn-warning" routerLink="/account/dang-ki">Đăng ký</button>
    </div>
</div>