import { Component, OnInit } from '@angular/core';

import * as C from '../const/const';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  img = [
    C.img + "/home_intro.jpg",
    C.img + "/giao-thong.png",
    C.img + "/phan-tich-khuon-mat.png",
    C.img + "/search.jpg",
    C.img + "/so-sanh.jpg",
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
