import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tech-item-carousel',
  templateUrl: './tech-item-carousel.component.html',
  styleUrls: ['./tech-item-carousel.component.css']
})
export class TechItemCarouselComponent implements OnInit {
@Input('item')
item: any;
@Input('choose')
choose:any;
  constructor(private router: Router) { }

  ngOnInit(): void {
    console.log(this.item)
  }

  goToItem() {
    if(this.choose === 1) 
      this.router.navigate(['/app/' + this.item.path]);
    else
      this.router.navigate(['/item/' + this.item.slug]);
  }

}
