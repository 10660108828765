import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-equal-event-analytics',
  templateUrl: './equal-event-analytics.component.html',
  styleUrls: ['./equal-event-analytics.component.css']
})
export class EqualEventAnalyticsComponent implements OnInit {
  data = [
    {
      "id":1,
      "name": "Phát hiện đám cháy",
      "main": {
        "time": "10p20s"
      }
    }
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
