<div class="btn-group" dropdown>
    <button id="button-animated" dropdownToggle type="button" class="btn btn-primary dropdown-toggle" aria-controls="dropdown-animated">
      +84 <span class="caret"></span>
    </button>
    <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-animated">
        <!-- <li role="menuitem"><a class="dropdown-item" href="#">Action</a></li>
        <li role="menuitem"><a class="dropdown-item" href="#">Another action</a>
        </li>
        <li role="menuitem"><a class="dropdown-item" href="#">Something else
        here</a></li> -->
    </ul>
</div>