import { Component, OnInit } from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';
import * as C from '../../../const/const';

@Component({
  selector: 'app-face-compare-analysis',
  templateUrl: './face-compare-analysis.component.html',
  styleUrls: ['./face-compare-analysis.component.css']
})
export class FaceCompareAnalysisComponent implements OnInit {
  carouselConfig: NguCarouselConfig = {
    grid: {xs:3, sm:3, md: 4, lg: 4, all: 0},
    slide: 1,
    speed: 200,
    touch: true,
    velocity: 0,
    loop: true,
    // interval: {
    //   timing: 2000,
    //   initialDelay: 1000,
    // },
    point: {
      visible: true,
      hideOnSingleSlide: true
    }
  };

  constructor() { }
  img = [
    C.img + "/hieutm.jpg",
    C.img + "/hanhdt1.jpg",
    C.img + "/hanhdt1.png",
    C.img + "/linhntm17.jpg",
    C.img + "/linhntm17.png",
    C.img + "/phuongnm23.jpg",
    C.img + "/phuongnm23.jpg" 
  ]
  ngOnInit(): void {
  }

}
