import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-equal-object-detected',
  templateUrl: './equal-object-detected.component.html',
  styleUrls: ['./equal-object-detected.component.css']
})
export class EqualObjectDetectedComponent implements OnInit {
  data = [
    {
      "id":1,
      "name": "Tụ tập đám đông",
      "main": {
        "time": "10p20s"
      }
    },
    {
      "id":2,
      "name": "Đếm đối tượng",
      "main": {
        "time": "10p20s"
      }
    },
    {
      "id":3,
      "name": "Đồ vật bị bỏ quên",
      "main": {
        "time": "10p20s"
      }
    },
    {
      "id":4,
      "name": "Đồ vật bị lấy mất",
      "main": {
        "time": "10p20s"
      }
    },
    {
      "id":5,
      "name": "Vượt xe trong vùng cấm",
      "main": {
        "time": "10p20s"
      }
    }
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
