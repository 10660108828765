<div class="ml-3 mr-3 text-center">
    <img src="assets/img/dectect-license-plate.png" class="img-fluid mb-4 img" />
    <div class="d-flex">
        <div class="w-25"></div>
        <div class="w-50">
            <input type="file" name="file" id="file" class="inputfile" />
            <label for="file" class="text-center"><i class="fa fa-upload" aria-hidden="true"></i>
                </label>
            <div class="text mb-2">(tải lên file .jpg, .png dung lượng không quá 5MB)</div>
        </div>
        <div class="w-25"></div>
    </div>
</div>