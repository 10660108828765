<ngu-carousel [inputs]="carouselConfig" [dataSource]="data" class="carousel-container">
    <div *nguCarouselDef="let item;" (deferLoad)="data.show=true" class="item">
        <app-item-carousel [item]="item"></app-item-carousel>
    </div>

    <div NguCarouselNext class="move-btn next">
        <i class="fa fa-angle-right" aria-hidden="true"></i>
    </div>
    <div NguCarouselPrev class="move-btn prev">
        <i class="fa fa-angle-left" aria-hidden="true"></i>
    </div>
</ngu-carousel>