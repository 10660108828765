<div class="pl-3 pr-3">
    <div class="mb-3 d-flex  align-items-center">
        <div class="text-right w-100">
            <button class="btn btn-primary">Bắt đầu</button>
        </div>
    </div>
    <video class="img video" controls>
        <source src="assets/video/videoplayback.mp4" type="video/mp4">
    </video>
    <div class="d-flex mt-2">
        <div class="w-25 text-center">
            <input type="file" name="file" id="file" class="inputfile" />
            <label for="file" class="text-center"><i class="fa fa-upload" aria-hidden="true"></i>
            </label>
            <div class="text mb-2">(tải lên file .mp4, dung lượng không quá 2GB)</div>
        </div>
        <div class="w-75 text-center">
            <input class="pl-2 w-75 p-1" placeholder="URL camera" />
        </div>
    </div>
</div>
<p class="text-center">Bộ sưu tập</p>
<ngu-carousel [inputs]="carouselConfig" [dataSource]="img" class="carousel-container pl-3 pr-3 mb-3">
    <div *nguCarouselDef="let item;" class="item text-center">
        <img src={{item}} class="img-fluid album" />
    </div>
    <div NguCarouselNext class="move-btn next">
        <i class="fa fa-angle-right" aria-hidden="true"></i>
    </div>
    <div NguCarouselPrev class="move-btn prev">
        <i class="fa fa-angle-left" aria-hidden="true"></i>
    </div>
</ngu-carousel>
<div class="d-flex mb-3">
    <div class="w-25"></div>
    <div class="album-input w-50 text-center">
        <input type="file" name="file" id="file" class="inputfile" />
        <label for="file" class="text-center">
            <i class="fa fa-upload" aria-hidden="true"></i>
        </label>
    </div>
    <div class="w-25"></div>
</div>