import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-equal-face-search',
  templateUrl: './equal-face-search.component.html',
  styleUrls: ['./equal-face-search.component.css']
})
export class EqualFaceSearchComponent implements OnInit {
data = [
  {
    img: 'assets/img/hieutm.jpg',
    percent: '99%'
  },
  {
    img: 'assets/img/hanhdt1.jpg',
    percent: '99%'
  },
  {
    img: 'assets/img/linhntm17.jpg',
    percent: '99%'
  },
  {
    img: 'assets/img/linhntm17.jpg',
    percent: '99%'
  }
]
  constructor() { }

  ngOnInit(): void {
  }

}
