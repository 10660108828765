import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { TechComponent } from './tech/tech.component';
import { AccountComponent } from './accounts/account/account.component';
import { ApplicationComponent } from './application/application.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { LoginComponent } from './authentication/login/login.component';
import { SignupComponent } from './authentication/signup/signup.component';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
        pathMatch: 'full'
      },
      {
        path: 'item/:slug',
        component: TechComponent
      },
      {
        path: 'app/:slug',
        component: ApplicationComponent
      }
    ]
  },
  {
    path: 'account/dang-ki',
    component: AccountComponent
  },
  {
    path: 'dang-ky',
    component: SignupComponent
  },
  {
    path: 'dang-nhap',
    component: LoginComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
