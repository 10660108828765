import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-equal',
  templateUrl: './equal.component.html',
  styleUrls: ['./equal.component.css']
})
export class EqualComponent implements OnInit {
@Input() slug:any;
equal=true;
data = {
  equal: "Cùng một người",
  number: "99%"
}
json = JSON.stringify({
  "faces1": [
    {
      "face_rectangle": {
        "width": 263,
        "top": 212,
        "left": 126,
        "height": 263
      },
      "face_token": "709b9b2cb2f57b34dc8fb526c9d338c7"
    }
  ],
  "faces2": [
    {
      "face_rectangle": {
        "width": 261,
        "top": 215,
        "left": 133,
        "height": 261
      },
      "face_token": "9d572bf8f3bb33cafa33dac90e3b59c4"
    }
  ],
  "time_used": 427,
  "thresholds": {
    "1e-3": 62.327,
    "1e-5": 73.975,
    "1e-4": 69.101
  },
  "confidence": 88.221,
  "image_id2": "SPedNTNWqgPmupahuTaSZQ==",
  "image_id1": "uADw4RAdkIsQxhaDMwiqig==",
  "request_id": "1588943646,7b245a89-263a-4045-80db-b1934b457db7"
}, undefined, 4)
  constructor() { }

  ngOnInit(): void {
  }

  changeEqual(i: boolean) {
    this.equal = i;
  }

}
