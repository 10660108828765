import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-p-collapse',
  templateUrl: './p-collapse.component.html',
  styleUrls: ['./p-collapse.component.css']
})
export class PCollapseComponent implements OnInit {
  @Input('item')
  item:any;

  isCollapsed = true;

  constructor() { }

  ngOnInit(): void {
  }

}
