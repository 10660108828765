<div *ngIf="slug === 'so-sanh-khuon-mat'" class="row">
    <div class="col-sm-6">
        <app-face-compare></app-face-compare>
    </div>
    <div class="col-sm-6">
        <app-equal [slug]="slug"></app-equal>
    </div>
</div>
<div *ngIf="slug === 'tim-kiem-khuon-mat'" style="overflow-x: hidden;" class="row">
    <div class="col-lg-6">
        <app-face-search></app-face-search>
    </div>
    <div class="col-lg-6">
        <app-equal [slug]="slug"></app-equal>
    </div>
</div>
<div *ngIf="slug === 'phan-tich-khuon-mat'" class="row">
    <div class="col-md-6">
        <app-face-analytics></app-face-analytics>
    </div>
    <div class="col-md-6">
        <app-equal [slug]="slug"></app-equal>
    </div>
</div>
<div *ngIf="slug === 'nhan-biet-bien-so'" class="row">
    <div class="col-sm-6">
        <app-dectect-license-plate></app-dectect-license-plate>
    </div>
    <div class="col-sm-6">
        <app-equal [slug]="slug"></app-equal>
    </div>
</div>
<div *ngIf="slug === 'phan-tich-giao-thong'" class="row">
    <div class="col-md-6">
        <app-traffic-analysis></app-traffic-analysis>
    </div>
    <div class="col-md-6">
        <app-equal [slug]="slug"></app-equal>
    </div>
</div>
<div *ngIf="slug === 'phan-tich-doi-tuong'" class="row">
    <div class="col-md-6">
        <app-object-detected></app-object-detected>
    </div>
    <div class="col-md-6">
        <app-equal [slug]="slug"></app-equal>
    </div>
</div>
<div *ngIf="slug === 'phan-tich-hanh-vi'" class="row">
    <div class="col-md-6">
        <app-loitering-detected></app-loitering-detected>
    </div>
    <div class="col-md-6">
        <app-equal [slug]="slug"></app-equal>
    </div>
</div>
<div *ngIf="slug === 'phan-tich-su-kien'" class="row">
    <div class="col-md-6">
        <app-event-analytics></app-event-analytics>
    </div>
    <div class="col-md-6">
        <app-equal [slug]="slug"></app-equal>
    </div>
</div>
<div *ngIf="slug === 'phan-tich-so-sanh-khuon-mat'" class="row">
    <div class="col-md-6">
        <app-face-compare-analysis></app-face-compare-analysis>
    </div>
    <div class="col-md-6">
        <app-equal [slug]="slug"></app-equal>
    </div>
</div>