import { Component, OnInit } from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';
import * as C from '../../../../const/const';

@Component({
  selector: 'app-equal-face-compare-analysis',
  templateUrl: './equal-face-compare-analysis.component.html',
  styleUrls: ['./equal-face-compare-analysis.component.css']
})
export class EqualFaceCompareAnalysisComponent implements OnInit {
  carouselConfig: NguCarouselConfig = {
    grid: {xs:2, sm:2.5, md: 3.5, lg: 4, all: 0},
    slide: 1,
    speed: 200,
    touch: true,
    velocity: 0,
    loop: true,
    // interval: {
    //   timing: 2000,
    //   initialDelay: 1000,
    // },
    point: {
      visible: true,
      hideOnSingleSlide: true
    }
  };
  data = [
    {
      img_album: C.img + "/hanhdt1.jpg",
      img_detected: {
        image: C.img + "/hanhdt1.png",
        percent: '90,9%'
      },
      age: {
        age: '30-40',
        percent: '85,9%'
      },
      gender: {
        gender: 'Nữ',
        percent: '80,9%'
      },
      status: {
        status: 'Vui vẻ',
        percent: '85,9%'
      },
      time: '08:00:00'
    },
    {
      img_album: C.img + "/linhntm17.jpg",
      img_detected: {
        image: C.img + "/linhntm17.png",
        percent: '90,9%'
      },
      age: {
        age: '20-30',
        percent: '95%'
      },
      gender: {
        gender: 'Nữ',
        percent: '80,9%'
      },
      status: {
        status: 'Vui vẻ',
        percent: '85,9%'
      },
      time: '08:00:00'
    }
  ];
  selected = 0;
  constructor() { }

  ngOnInit(): void {
  }

  selectImg(i) {
    this.selected = i;
    console.log(i)
  }

}
