import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-equal-traffic-analysis',
  templateUrl: './equal-traffic-analysis.component.html',
  styleUrls: ['./equal-traffic-analysis.component.css']
})
export class EqualTrafficAnalysisComponent implements OnInit {
  data = [
    {
      "id":1,
      "name": "Phương tiện dừng/đỗ trái phép",
      "main": {
        "time": "10p20s"
      }
    },
    {
      "id":2,
      "name": "Phương tiện vượt đèn đỏ",
      "main": {
        "time": "10p20s"
      }
    },
    {
      "id":3,
      "name": "Phương tiện đi sai làn",
      "main": {
        "time": "10p20s"
      }
    },
    {
      "id":4,
      "name": "Phương tiện đi ngược chiều",
      "main": {
        "time": "10p20s"
      }
    },
    {
      "id":5,
      "name": "Vượt xe trong vùng cấm",
      "main": {
        "time": "10p20s"
      }
    }
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
