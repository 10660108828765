import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.css']
})
export class MainHeaderComponent implements OnInit {
  items = ['SDK', 'ỨNG DỤNG', 'GIA', 'TÀI LIỆU', 'LIÊN HỆ']
  techs = [
    {
      title: 'Nhận diện khuôn mặt',
      main: [
        {
          name: 'So sánh khuôn mặt',
          slug: 'so-sanh-khuon-mat'
        },
        {
          name: 'Tìm kiếm khuôn mặt',
          slug: 'tim-kiem-khuon-mat'
        },
        {
          name: 'Phân tích khuôn mặt (cảm xúc, giới tính, độ tuổi)',
          slug: 'phan-tich-khuon-mat'
        }
      ],
      open: true
    },
    {
      title: 'Nhận diện biển số',
      main: [
        {
          name: 'Tìm kiếm biển số',
          slug: 'tim-kiem-bien-so'
        },
        {
          name: 'Nhận biết biển số',
          slug: 'nhan-biet-bien-so'
        }
      ],
      open: true
    },
    {
      title: 'Phân tích video (IVA)',
      main: [
        {
          name:'Phân tích giao thông',
          slug: 'phan-tich-giao-thong'
        },
        {
          name: 'Phân tích khuôn mặt',
          slug: 'phan-tich-so-sanh-khuon-mat'
        },
        {
          name: 'Phân tích đối tượng',
          slug: 'phan-tich-doi-tuong'
        },
        {
          name: 'Phân tích hành vi',
          slug: 'phan-tich-hanh-vi'
        },
        {
          name:  'Phân tích sự kiện',
          slug: 'phan-tich-su-kien'
        }
      ],
      open: true
    }
  ]

  constructor() { }

  ngOnInit(): void {
  }
  ngOnDestroy(): void { 

  }
}
