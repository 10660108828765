<carousel [isAnimated]="true" [noPause]="true">
    <slide *ngFor="let item of img">
        <img src="{{item}}" alt="first slide" class="img-fluid home-intro">
    </slide>
</carousel>
<h3 class="text-center mt-4 title-tech ">Giới thiệu Công nghệ</h3>
<hr class="text-center hr-tech mb-4 " width="15% " />
<app-intro-tech></app-intro-tech>
<!-- <h2 class="text-center mt-4 title-tech ">Tại sao chọn Viettel IVA Platform</h2>
<hr class="text-center hr-tech mb-2 " width="15% " /> -->
<!-- <div class="container ">
    <div class="row text-center ">
        <div class="col-md-4 ">
            <img height="100px " src="assets/img/accurate.png " />
            <p class="name ">Độ chính xác</p>
            <p class="description ">Viettel IVA Platform đạt độ chính xác hàng đầu trong ngành, trong các ứng dụng thực tế.</p>
        </div>
        <div class="col-md-4 ">
            <img height="100px " src="assets/img/hop-tac.png " />
            <p class="name ">Tính tin cậy</p>
            <p class="description ">Viettel IVA Platform có tính sẵn sàng cao, luôn ở trạng thái online 24/7</p>
        </div>
        <div class="col-md-4 ">
            <img height="100px " src="assets/img/kha-nang-tich-hop.jpg " />
            <p class="name ">Khả năng tích hợp</p>
            <p class="description ">Viettel IVA Platform có thể tích hợp vào bất kỳ hệ thống kinh doanh của khách hàng trong thời gian tối thiểu.
            </p>
        </div>
    </div>
</div> -->
<div class="choose pt-4 pb-4 text-center ">
    <div class="container ">
        <h3 class="why-tech ">Tại sao chọn Viettel IVA Platform</h3>
        <hr class="text-center hr-tech-2 mb-4 " width="15% " />
        <div class="row text-center ">
            <div class="col-md-4 ">
                <img height="100px " src="assets/img/accurate.png " />
                <p class="name ">Độ chính xác</p>
                <p class="description ">Viettel IVA Platform đạt độ chính xác hàng đầu trong ngành, trong các ứng dụng thực tế.</p>
            </div>
            <div class="col-md-4 ">
                <img height="100px " src="assets/img/hop-tac.png " />
                <p class="name ">Tính tin cậy</p>
                <p class="description ">Viettel IVA Platform có tính sẵn sàng cao, luôn ở trạng thái online 24/7</p>
            </div>
            <div class="col-md-4 ">
                <img height="100px " src="assets/img/kha-nang-tich-hop.jpg " />
                <p class="name ">Khả năng tích hợp</p>
                <p class="description ">Viettel IVA Platform có thể tích hợp vào bất kỳ hệ thống kinh doanh của khách hàng trong thời gian tối thiểu.
                </p>
            </div>
        </div>
    </div>
</div>
<div class="text-center pb-4 ">
    <h3 class="mt-4 title-tech ">Sẵn sàng để bắt đầu?</h3>
    <p class="title-tech " style="font-size: 20px; ">Đăng ký và bắt đầu sử dụng bất cứ dịch vụ nào bạn muốn</p>
    <div>
        <button class="btn btn-primary pl-4 pr-4 mr-2 " routerLink="/account/dang-ki ">Đăng ký</button>
        <button class="btn btn-outline-dark pl-4 pr-4">Liên hệ</button>
    </div>
</div>