<div class="row pl-3 pr-3">
    <div class="col-6">
        <img src="assets/img/hanhdt1.jpg" class="img-fluid mb-4 img" />
        <input type="file" name="file" id="file" class="inputfile" />
        <label for="file" class="text-center"><i class="fa fa-upload" aria-hidden="true"></i>
        </label>
    </div>
    <div class="col-6">
        <img src="assets/img/hanhdt1.jpg" class="img-fluid mb-4 img" />
        <input type="file" name="file" id="file" class="inputfile" />
        <label for="file" class="text-center"><i class="fa fa-upload" aria-hidden="true"></i>
        </label>
    </div>
</div>