<div class="row ml-3 mr-3">
    <div class="col-sm-4">
        <img src="assets/img/hanhdt1.jpg" class="img-find mb-4 img" />
        <input type="file" name="file" id="file" class="inputfile" />
        <label for="file" class="text-center"><i class="fa fa-upload" aria-hidden="true"></i>
        </label>
    </div>
    <div class="col-sm-8">
        <div class="box-child row">
            <div *ngFor="let i of img" class="col-4 item text-center">
                <img src="{{i}}" class="img-child p-2" />
            </div>
        </div>
        <div class="mt-4">
            <input type="file" name="file" id="file" class="inputfile" />
            <label for="file" class="text-center">
                <i class="fa fa-upload" aria-hidden="true"></i>
            </label>
        </div>
    </div>
</div>