import { Component, OnInit } from '@angular/core';
import * as face from '../../../assets/json/face.json';
import * as video from '../../../assets/json/video.json';
@Component({
  selector: 'app-intro-tech',
  templateUrl: './intro-tech.component.html',
  styleUrls: ['./intro-tech.component.css']
})
export class IntroTechComponent implements OnInit {
  data: any;
  i = 1
  face = (face as any).default;
  video = (video as any).default;

  constructor() { }

  ngOnInit(): void {
    this.data = this.face;
  }

  changeData(i) {
    this.i = i;
    if(i === 1) {
      this.data = this.face;
    } else {
      this.data = this.video;
    }
  }

}
