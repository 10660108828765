import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loitering-detected',
  templateUrl: './loitering-detected.component.html',
  styleUrls: ['./loitering-detected.component.css']
})
export class LoiteringDetectedComponent implements OnInit {
  json = JSON.stringify({ "ocr_lpb": 1, "stopline_y": 750, "traffic_light": { "green": [1762, 405, 1775, 418], "red": [1764, 350, 1777, 363]}}, undefined, 4)
  json_input;
  constructor() { }

  ngOnInit(): void {
  }
  changeJson() {
    this.json_input = this.json;
  }
}
