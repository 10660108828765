<div class="pl-3 pr-3">
    <div class="mb-3 d-flex  align-items-center">
        <div class="text-right w-100">
            <button class="btn btn-primary">Bắt đầu</button>
        </div>
    </div>
    <video class="img video" controls>
        <source src="assets/video/traffic.mp4" type="video/mp4">
    </video>
    <div class="d-flex mt-2">
        <div class="w-25 text-center">
            <input type="file" name="file" id="file" class="inputfile" />
            <label for="file" class="text-center"><i class="fa fa-upload" aria-hidden="true"></i>
            </label>
            <div class="text mb-2">(tải lên file .mp4, dung lượng không quá 2GB)</div>
        </div>
        <div class="w-75 text-center">
            <input class="pl-2 w-75 p-1" placeholder="URL camera" />
        </div>
    </div>
</div>