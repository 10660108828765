import { Component, OnInit } from '@angular/core';
import * as C from '../../../const/const';
@Component({
  selector: 'app-face-search',
  templateUrl: './face-search.component.html',
  styleUrls: ['./face-search.component.css']
})
export class FaceSearchComponent implements OnInit {
  img = [
    C.img + "/hieutm.jpg",
    C.img + "/hanhdt1.jpg",
    C.img + "/hanhdt1.png",
    C.img + "/linhntm17.jpg",
    C.img + "/linhntm17.png",
    C.img + "/phuongnm23.jpg",
    C.img + "/phuongnm23.jpg" 
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
