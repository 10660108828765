import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NguCarouselModule } from '@ngu/carousel';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { MainHeaderComponent } from './main-header/main-header.component';
import { IntroTechComponent } from './components/intro-tech/intro-tech.component';
import { SlideBarComponent } from './components/slide-bar/slide-bar.component';
import { SlideCarouselComponent } from './components/slide-carousel/slide-carousel.component';
import { TechComponent } from './tech/tech.component';
import { ItemCarouselComponent } from './components/item-carousel/item-carousel.component';
import { MainFooterComponent } from './main-footer/main-footer.component';
import { AccountComponent } from './accounts/account/account.component';
import { TestComponent } from './components/test/test.component';
import { FaceCompareComponent } from './components/test/face-compare/face-compare.component';
import { EqualComponent } from './components/test/equal/equal.component';
import { FaceSearchComponent } from './components/test/face-search/face-search.component';
import { EqualFaceCompareComponent } from './components/test/equal/equal-face-compare/equal-face-compare.component';
import { EqualFaceSearchComponent } from './components/test/equal/equal-face-search/equal-face-search.component';
import { FaceAnalyticsComponent } from './components/test/face-analytics/face-analytics.component';
import { EqualFaceAnalyticsComponent } from './components/test/equal/equal-face-analytics/equal-face-analytics.component';
import { DectectLicensePlateComponent } from './components/test/dectect-license-plate/dectect-license-plate.component';
import { EqualDetectLicensePlateComponent } from './components/test/equal/equal-detect-license-plate/equal-detect-license-plate.component';
import { TrafficAnalysisComponent } from './components/test/traffic-analysis/traffic-analysis.component';
import { EqualTrafficAnalysisComponent } from './components/test/equal/equal-traffic-analysis/equal-traffic-analysis.component';
import { PCollapseComponent } from './components/test/equal/equal-traffic-analysis/p-collapse/p-collapse.component';
import { TechPCollapseComponent } from './components/slide-bar/tech-p-collapse/tech-p-collapse.component';
import { ObjectDetectedComponent } from './components/test/object-detected/object-detected.component';
import { EqualObjectDetectedComponent } from './components/test/equal/equal-object-detected/equal-object-detected.component';
import { LoiteringDetectedComponent } from './components/test/loitering-detected/loitering-detected.component';
import { EqualLoiteringDetectedComponent } from './components/test/equal/equal-loitering-detected/equal-loitering-detected.component';
import { EventAnalyticsComponent } from './components/test/event-analytics/event-analytics.component';
import { EqualEventAnalyticsComponent } from './components/test/equal/equal-event-analytics/equal-event-analytics.component';
import { TechSideCarouselComponent } from './tech/tech-side-carousel/tech-side-carousel.component';
import { TechItemCarouselComponent } from './tech/tech-side-carousel/tech-item-carousel/tech-item-carousel.component';
import { ApplicationComponent } from './application/application.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { EqualFaceCompareAnalysisComponent } from './components/test/equal/equal-face-compare-analysis/equal-face-compare-analysis.component';
import { FaceCompareAnalysisComponent } from './components/test/face-compare-analysis/face-compare-analysis.component';
import { SignupComponent } from './authentication/signup/signup.component';
import { LoginComponent } from './authentication/login/login.component';
import { PhoneDropdownComponent } from './authentication/components/phone-dropdown/phone-dropdown.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MainLayoutComponent,
    MainHeaderComponent,
    IntroTechComponent,
    SlideBarComponent,
    SlideCarouselComponent,
    TechComponent,
    ItemCarouselComponent,
    MainFooterComponent,
    AccountComponent,
    TestComponent,
    FaceCompareComponent,
    EqualComponent,
    FaceSearchComponent,
    EqualFaceCompareComponent,
    EqualFaceSearchComponent,
    FaceAnalyticsComponent,
    EqualFaceAnalyticsComponent,
    DectectLicensePlateComponent,
    EqualDetectLicensePlateComponent,
    TrafficAnalysisComponent,
    EqualTrafficAnalysisComponent,
    PCollapseComponent,
    TechPCollapseComponent,
    ObjectDetectedComponent,
    EqualObjectDetectedComponent,
    LoiteringDetectedComponent,
    EqualLoiteringDetectedComponent,
    EventAnalyticsComponent,
    EqualEventAnalyticsComponent,
    TechSideCarouselComponent,
    TechItemCarouselComponent,
    ApplicationComponent,
    PageNotFoundComponent,
    EqualFaceCompareAnalysisComponent,
    FaceCompareAnalysisComponent,
    SignupComponent,
    LoginComponent,
    PhoneDropdownComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NguCarouselModule,
    BrowserAnimationsModule,
    PopoverModule.forRoot(),
    CollapseModule.forRoot(),
    CarouselModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
