<div class="box d-flex pt-2 pb-2">
    <img src="assets/img/hanhdt1.jpg" class="img-child w-25 m-1" />
    <div class="w-75 ml-1 mt-3">
        <div class="d-flex justify-content-around">
            <div>Cảm xúc: Vui.</div>
            <div>95%</div>
        </div>
        <div class="d-flex justify-content-around">
            <div>Giới tính: Nữ.</div>
            <div>90%</div>
        </div>
        <div class="d-flex justify-content-around">
            <div>Độ tuổi: 31 - 40.</div>
            <div>80%</div>
        </div>
    </div>
</div>