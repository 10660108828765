import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import * as face from '../../assets/json/face.json';
import * as video from '../../assets/json/video.json';
@Component({
  selector: 'app-tech',
  templateUrl: './tech.component.html',
  styleUrls: ['./tech.component.css']
})
export class TechComponent implements OnInit {
  slug;
  item;
  face = (face as any).default;
  video = (video as any).default;

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {
    router.events.subscribe((events) => {
      this.route.params.subscribe(params => {
        this.slug = params['slug'];
      })
      this.checkSlug(this.face);
      this.checkSlug(this.video);
      window.scrollTo(0, 0);
    })
   }

  ngOnInit(): void {
  }

  toTop(){ 
    document.getElementById("target").scrollIntoView();
  }


  checkSlug(data:any) {
    for(let i of data) {
      if(i.slug === this.slug) {
        this.item = i;
      }
    }
  }


}
