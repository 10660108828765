<div class="equal ml-3 mr-3 mb-4">
    <div class="d-flex">
        <div class="w-50 text-center item" [ngClass]="{active: equal}" (click)="changeEqual(true)">Kết quả</div>
        <div class="w-50 text-center item" [ngClass]="{active: !equal}" (click)="changeEqual(false)">JSON</div>
    </div>
    <div class="pl-5 pr-5 pt-3 pb-3 equal-child" *ngIf="equal && slug !== 'phan-tich-so-sanh-khuon-mat'">
        <div *ngIf="slug === 'so-sanh-khuon-mat'">
            <app-equal-face-compare [data]="data"></app-equal-face-compare>
        </div>
        <div *ngIf="slug === 'tim-kiem-khuon-mat'">
            <app-equal-face-search></app-equal-face-search>
        </div>
        <div *ngIf="slug === 'phan-tich-khuon-mat'">
            <app-equal-face-analytics></app-equal-face-analytics>
        </div>
        <div *ngIf="slug === 'nhan-biet-bien-so'">
            <app-equal-detect-license-plate></app-equal-detect-license-plate>
        </div>
        <div *ngIf="slug === 'phan-tich-giao-thong'">
            <app-equal-traffic-analysis></app-equal-traffic-analysis>
        </div>
        <div *ngIf="slug === 'phan-tich-doi-tuong'">
            <app-equal-object-detected></app-equal-object-detected>
        </div>
        <div *ngIf="slug === 'phan-tich-hanh-vi'">
            <app-equal-loitering-detected></app-equal-loitering-detected>
        </div>
        <div *ngIf="slug === 'phan-tich-su-kien'">
            <app-equal-event-analytics></app-equal-event-analytics>
        </div>
    </div>
    <div class="pl-2 pr-2 pt-3 pb-3 equal-child" style="overflow-y: auto;overflow-x: hidden;" *ngIf="slug === 'phan-tich-so-sanh-khuon-mat'">
        <app-equal-face-compare-analysis></app-equal-face-compare-analysis>
    </div>
    <div class="pl-5 pr-5 pt-3 pb-3 equal-child" *ngIf="json && !equal">
        <textarea class="w-100 h-100">{{json}}</textarea>
    </div>
</div>