import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-event-analytics',
  templateUrl: './event-analytics.component.html',
  styleUrls: ['./event-analytics.component.css']
})
export class EventAnalyticsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
