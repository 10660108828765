<div class="home" style="overflow-x: hidden;">
    <div class="home-intro pl-5 pr-5" style="background-image: url({{item.img}});margin: auto;">
        <div class="h-25 mobile"></div>
        <h2>{{item.name}}</h2>
        <hr align="left" width="10%">
        <div class="description">{{item.description}}</div>
        <button type="button" class="mt-1 mb-1 btn btn-primary" (click)="toTop()">Bắt đầu</button>
    </div>
    <h2 class="text-center mt-4 title-tech" id="target">Thử nghiệm</h2>
    <hr class="text-center hr-tech mb-4" width="10%" />
    <app-test [slug]="slug"></app-test>
    <div class="bg-application pt-1 pb-1">
        <h2 class="text-center mt-4 title-tect">Ứng dụng</h2>
        <hr class="text-center hr-tech mb-4" width="10%" />
        <app-tech-side-carousel [slug]="slug" [item]=1></app-tech-side-carousel>
    </div>
    <div class="pt-1 pb-1">
        <h2 class="text-center mt-4 title-tect">Công nghệ liên quan</h2>
        <hr class="text-center hr-tech mb-4" width="10%" />
        <app-tech-side-carousel [slug]="slug" [item]=2>
        </app-tech-side-carousel>
    </div>
</div>
<div class="bg-footer pt-2 pb-2 text-center">
    <div class="list pt-2 pb-2">
        <div class="item">
            <i class="fa fa-user-plus" aria-hidden="true"></i>
        </div>
        <div class="item"><i class="fa fa-file-text" aria-hidden="true"></i></div>
        <div class="item"><i class="fa fa-ils" aria-hidden="true"></i></div>
    </div>
    <div class="pt-2 pb-2">
        <span class="create-account">Tạo tài khoản</span>
        <span class="create-api">Tạo API Key</span>
        <span class="call-api">Gọi APIs</span>
    </div>
    <button class="sign-up pl-4 pr-4 pt-1 pb-1" routerLink="/account/dang-ki">Đăng ký và bắt đầu sử dụng</button>
</div>