import { Component, OnInit, Input } from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';
@Component({
  selector: 'app-slide-carousel',
  templateUrl: './slide-carousel.component.html',
  styleUrls: ['./slide-carousel.component.css']
})
export class SlideCarouselComponent implements OnInit {
  @Input('data') data:any;
  carouselConfig: NguCarouselConfig = {
    grid: {xs:1, sm:2, md: 3, lg: 4, all: 0},
    slide: 1,
    speed: 200,
    touch: true,
    velocity: 0,
    loop: false,
    interval: {
      timing: 2000,
      initialDelay: 1000,
    },
    point: {
      visible: true,
      hideOnSingleSlide: true
    }
  };
  constructor() { }

  ngOnInit(): void {
  }

}
