<ngu-carousel [inputs]="carouselConfig" [dataSource]="data" class="carousel-container">
    <div *nguCarouselDef="let i;" class="d-flex justify-content-around" (deferLoad)="data.show=true" class="item" style="display: inline;">
        <app-tech-item-carousel [item]="i" [choose]="item"></app-tech-item-carousel>
    </div>

    <div NguCarouselNext class="move-btn next">
        <i class="fa fa-angle-right" aria-hidden="true"></i>
    </div>
    <div NguCarouselPrev class="move-btn prev">
        <i class="fa fa-angle-left" aria-hidden="true"></i>
    </div>
</ngu-carousel>