<ngu-carousel [inputs]="carouselConfig" [dataSource]="data" class="carousel-container pl-3 pr-3 mb-3">
    <div *nguCarouselDef="let item;index as i" class="item text-center">
        <img src={{item.img_album}} class="img-fluid album" (click)="selectImg(i)" [ngClass]="{'img': selected === i}" />
    </div>
    <div NguCarouselNext class="move-btn next">
        <i class="fa fa-angle-right" aria-hidden="true"></i>
    </div>
    <div NguCarouselPrev class="move-btn prev">
        <i class="fa fa-angle-left" aria-hidden="true"></i>
    </div>
</ngu-carousel>
<hr class="mr-2 ml-2" />
<div *ngFor="let item of data;index as i">
    <div *ngIf="selected === i">
        <div class="row align-items-center p-1">
            <span class="result-title col-4">Ảnh nhận diện</span>
            <div class="col-5">
                <img class="album img-fluid " src={{item.img_detected.image}}/>
            </div>
            <span class="col-3">{{item.img_detected.percent}}</span>
        </div>
        <div class="row p-1">
            <span class="result-title col-4">Giới tính</span>
            <span class="col-5">{{item.gender.gender}}</span>
            <span class="col-3">{{item.gender.percent}}</span>
        </div>
        <div class="row p-1">
            <span class="result-title col-4">Độ tuổi</span>
            <span class="col-5">{{item.age.age}}</span>
            <span class="col-3">{{item.age.percent}}</span>
        </div>
        <div class="row p-1">
            <span class="result-title col-4">Trạng thái</span>
            <span class="col-5">{{item.status.status}}</span>
            <span class="col-3">{{item.status.percent}}</span>
        </div>
        <div class="row p-1">
            <span class="result-title col-4">Thời gian</span>
            <span class="col-8">{{item.time}}</span>
        </div>
    </div>
</div>