import { Component, OnInit, Input } from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';

import * as application from '../../../assets/json/face-compare.json';
import * as face from '../../../assets/json/face.json';
import * as traffic_analysis from "../../../assets/json/traffic-analysis.json";
import * as video from '../../../assets/json/video.json';

@Component({
  selector: 'app-tech-side-carousel',
  templateUrl: './tech-side-carousel.component.html',
  styleUrls: ['./tech-side-carousel.component.css']
})
export class TechSideCarouselComponent implements OnInit {
  @Input('item')
  item: any;
  @Input('slug')
  slug: any;
  grids = [
    {xs:1, sm: 2, md: 2, lg: 2, all: 0},
    {xs:1, sm: 2, md: 2, lg: 3, all: 0},
    {xs:1, sm: 2, md: 3, lg: 4, all: 0},
  ]

  application = (application as any).default;
  face = (face as any).default;
  traffic_analysis = (traffic_analysis as any).default;
  video = (video as any).default;

  data: any;
  carouselConfig: NguCarouselConfig = {
    grid: {xs:1, sm:2, md: 2, lg: 2, all: 0},
    slide: 1,
    speed: 200,
    touch: true,
    velocity: 0,
    loop: true,
    interval: {
      timing: 2000,
      initialDelay: 1000,
    },
    point: {
      visible: true,
      hideOnSingleSlide: true
    }
  };

  constructor() { }

  ngOnInit(): void {
    
  }

  ngOnChanges() {
    this.changeData();
  }

  changeData() {
    if(this.item === 1) {
      if(this.slug === 'so-sanh-khuon-mat') {
        this.data = this.application;
        this.carouselConfig.grid = this.grids[0];
      } else {
        this.data = this.traffic_analysis;
        this.carouselConfig.grid = this.grids[1];
      }
    }
    else {
      if(this.slug === 'so-sanh-khuon-mat' || 
        this.slug === 'tim-kiem-khuon-mat' ||
        this.slug === 'phan-tich-khuon-mat') {
        this.data = this.splitData(this.face, this.slug);
        this.carouselConfig.grid = this.grids[1];
      } else {
        this.data = this.splitData(this.video, this.slug);
        this.carouselConfig.grid = this.grids[1];
      }
    }
  }
  splitData(data: any, slug: any) {
    var out = data.filter((item:any) => {
      if(item.slug != slug)
        return item;
    }) 
    return out;
  }

}
