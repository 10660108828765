import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-equal-face-compare',
  templateUrl: './equal-face-compare.component.html',
  styleUrls: ['./equal-face-compare.component.css']
})
export class EqualFaceCompareComponent implements OnInit {
@Input() data:any;
  constructor() { }

  ngOnInit(): void {
    console.log(this.data)
  }

}
