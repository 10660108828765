<div class="login">
    <div class="container-fluid">
        <div class="card p-4">
            <div class="mb-3">Chào mừng bạn đến với Nền tảng Phân tích video</div>
            <div class="mb-3 d-flex">
                <div class="w-25"><span>Tài khoản</span></div>
                <div class="w-25"><span>Điện thoại</span></div>
            </div>
            <div class="mb-1 d-flex">
                <div [ngClass]="{'text-under': true}"></div>
                <div [ngClass]="{'text-under': false}"></div>
            </div>
            <form>
                <div class=" form-group ">
                    <input type="email " class="form-control " id="exampleInputEmail1 " aria-describedby="emailHelp " placeholder="Tên đăng nhập, email hoặc điện thoại">
                </div>
                <div class=" form-group d-flex">
                    <app-phone-dropdown class="mr-5"></app-phone-dropdown>
                    <input type="email " class="form-control " id="exampleInputEmail1 " aria-describedby="emailHelp " placeholder="Số điện thoại">
                </div>
                <div class="form-group ">
                    <input type="password " class="form-control " id="exampleInputPassword1 " placeholder="Mật khẩu ">
                </div>
                <button type="submit " class="btn btn-primary w-100 ">Submit</button>
                <div class="mt-3 ">
                    <a><u>Quên mật khẩu?</u></a>
                    <a class="float-right ">Đăng ký</a>
                </div>

            </form>
        </div>
    </div>
</div>