import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dectect-license-plate',
  templateUrl: './dectect-license-plate.component.html',
  styleUrls: ['./dectect-license-plate.component.css']
})
export class DectectLicensePlateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
