<div class="bars" (click)="openSideBar()" [ngClass]="{'open': open}">
    <i class="fa fa-bars fa-2x" aria-hidden="true"></i>
</div>
<div class="sidebar" [ngClass]="{'open': !open}">
    <div id="sidebar" class="menu p-2 overflow-auto">
        <div class="text-right close mr-1" (click)="closeSideBar()">
            <i class="fa fa-times" aria-hidden="true"></i>
        </div>
        <br/>
        <div>
            <div class="item" (click)="closeSideBar()" routerLink="">Home</div>
            <div class="item d-flex" (click)="tech = !tech" [attr.aria-expanded]="!tech" aria-controls="collapseBasic">
                <div class="w-75">Công nghệ</div>
                <div class="text-right mr-2 w-25"><i [ngClass]="{'fa-angle-up': tech,'fa-angle-down': !tech}" class="fa" aria-hidden="true"></i></div>
            </div>
            <ul id="collapseBasic" [collapse]="tech" [isAnimated]="true">
                <li *ngFor="let item of techs;index as i">
                    <app-tech-p-collapse (close)="closeSideBar()" [item]="item"></app-tech-p-collapse>
                </li>
            </ul>
            <div class="item" *ngFor="let i of items">{{i}}</div>
        </div>
        <div class="text-center pt-2 pb-2">
            <img height="100px" src="assets/img/viettel-icon.png" />
            <div class="permission ml-1 mr-1">Cơ quan chủ quản: Tập đoàn Công nghiệp - Viễn thông Quân đội. Giấy phép số: 144/GP-BC do Bộ Thông tin - Truyền thông cấp ngày 18/04/2007</div>
        </div>
    </div>
</div>