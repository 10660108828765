import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-tech-p-collapse',
  templateUrl: './tech-p-collapse.component.html',
  styleUrls: ['./tech-p-collapse.component.css']
})
export class TechPCollapseComponent implements OnInit {
@Input('item')
item: any;
@Output()
close = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }

  closeSideBar() {
    this.close.emit();
  }

}
