import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-equal-loitering-detected',
  templateUrl: './equal-loitering-detected.component.html',
  styleUrls: ['./equal-loitering-detected.component.css']
})
export class EqualLoiteringDetectedComponent implements OnInit {
  data = [
    {
      "id":1,
      "name": "Đột nhập vào vùng cấm",
      "main": {
        "time": "10p20s"
      }
    },
    {
      "id":2,
      "name": "Đối tượng đi qua vạch",
      "main": {
        "time": "10p20s"
      }
    },
    {
      "id":3,
      "name": "Đối tượng lảng vảng",
      "main": {
        "time": "10p20s"
      }
    },
    {
      "id":4,
      "name": "Đối tượng đánh nhau",
      "main": {
        "time": "10p20s"
      }
    },
    {
      "id":5,
      "name": "Phá hoại che khuất camera",
      "main": {
        "time": "10p20s"
      }
    }
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
