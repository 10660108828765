import { Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'app-slide-bar',
  templateUrl: './slide-bar.component.html',
  styleUrls: ['./slide-bar.component.css']
})
export class SlideBarComponent implements OnInit {
@Input('items') items:any;
@Input('techs') techs:any;
open = false;
tech = true;
  constructor() { }

  ngOnInit(): void {
  }

  openSideBar() {
    this.open = true;
    document.body.style.overflowY = 'hidden';
  }

  closeSideBar() {
      this.open = false;
      this.tech = true;
      for(let t of this.techs) {
        t.open = true;
      }
      document.body.style.overflowY = 'auto';
    }

}
