import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-equal-detect-license-plate',
  templateUrl: './equal-detect-license-plate.component.html',
  styleUrls: ['./equal-detect-license-plate.component.css']
})
export class EqualDetectLicensePlateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
