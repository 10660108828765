<div class="sign-up">
    <div class="container-fluid">
        <div class="card p-4">
            <div class="mb-3">Chào mừng bạn đến với Nền tảng Phân tích video</div>
            <div class="mb-3 d-flex">
                <div class="w-25"><span>Điện thoại</span></div>
                <div class="w-25"><span>Email</span></div>
            </div>
            <div class="mb-1 d-flex">
                <div [ngClass]="{'text-under': true}"></div>
                <div [ngClass]="{'text-under': false}"></div>
            </div>
            <form>
                <div class=" form-group ">
                    <input type="text " class="form-control " id="username" aria-describedby="username " placeholder="Tên đăng nhập">
                </div>
                <div class="d-flex justify-content-between">
                    <div class="form-group w-75">
                        <input type="number " class="form-control" id="phone " placeholder="Điện thoại">
                    </div>
                </div>
                <div class="d-flex justify-content-between">
                    <div class="form-group w-75">
                        <input type="number " class="form-control" id="code " placeholder="Nhập mã">
                    </div>
                    <div class="form-group">
                        <button type=" submit " class="btn btn-primary">Gửi</button>
                    </div>
                </div>
                <div class="form-group ">
                    <input type="password " class="form-control " id="password " placeholder="Mật khẩu ">
                </div>
                <button type="submit " class="btn btn-primary w-100 ">Submit</button>
                <div class="mt-3 ">
                    <a><u>Quên mật khẩu?</u></a>
                    <a class="float-right ">Đăng ký</a>
                </div>

            </form>
        </div>
    </div>
</div>