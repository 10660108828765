<div class="pl-3 pr-3">
    <div class="mb-3 d-flex  align-items-center">
        <div class="w-50">
            <div>Cấu hình vùng phân tích</div>
            <input class="pl-2" placeholder="/config.json" (click)="pop.toggle()" value="{{json_input}}" [popover]="template" triggers="" #pop="bs-popover" placement="top" />
            <ng-template #template>
                <div class="popover">
                    <textarea class="json">{{json}}</textarea>
                    <br/>
                    <div class="text-center">
                        <button class="btn btn-outline-secondary mr-1" (click)="changeJson();pop.hide()">Đồng ý</button>
                        <button class="btn btn-outline-secondary" (click)="pop.hide()">Hủy</button>
                    </div>
                </div>
            </ng-template>
        </div>
        <div class="text-right w-50">
            <button class="btn btn-primary">Bắt đầu</button>
        </div>
    </div>
    <video class="img video" controls>
        <source src="assets/video/traffic.mp4" type="video/mp4">
    </video>
    <div class="d-flex mt-2">
        <div class="w-25 text-center">
            <input type="file" name="file" id="file" class="inputfile" />
            <label for="file" class="text-center"><i class="fa fa-upload" aria-hidden="true"></i>
            </label>
            <div class="text mb-2">(tải lên file .mp4, dung lượng không quá 2GB)</div>
        </div>
        <div class="w-75 text-center">
            <input class="pl-2 w-75 p-1" placeholder="URL camera" />
        </div>
    </div>
</div>